import React from "react"
import {
  ActivityBox,
  DiscussBox,
  DefinitionBox,
  CopyrightBox, ToSeeBox
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import { YouTubeVideo } from "../../../components/video"

const BuildingTheCathedral = () => (
  <Layout title="Building the Cathedral">
    <SmallSectionHeader
      color={theme.colors.yellow.dark}
      image={headerImage}
      to="/how-did-they-build-that"
    >
      How did they build that?
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/how-did-they-build-that', name: 'How did they build that?' },
      { name: 'Building the Cathedral' }
    ]} />
    <Page>
      <h1>Building the Cathedral</h1>

      <p className="page-left">
        The Cathedral is built on top of the remains of a small Saxon church which Saint Augustine built when he re-introduced Christianity to the people of Kent from 597. The earliest parts of The Cathedral were built in the 1070s when the first Norman Archbishop, Lanfranc, rebuilt the Cathedral as a place of worship for a community of monks. The building continued to be expanded by the monks over the following years. In 1174 a terrible fire led to another major rebuilding project. A French master mason (a designer and builder working in stone) called William of Sens brought new building techniques and a different architectural style to Canterbury. In the 1300s, changing fashions led the Nave of The Cathedral to be knocked down and rebuilt to create the building we see today. The Great Cloister was then rebuilt to match it. In the 1490s the Bell Harry Tower was added.
      </p>
      <Figure
        className="page-right row-2"
      >
        <StaticImage
          src="./cathedral-stages.png"
          alt="The different stages of growth of the Cathedral building."
        />
      </Figure>
      <DefinitionBox title="Architecture" className="page-left">
        The style and design of a building.
      </DefinitionBox>

      <DiscussBox>
        Can you discuss why different events led to The Cathedral building to change over time?
      </DiscussBox>

      <h2>Arches</h2>
      <p className="page-left">
        Architectural historians (people who look at how buildings change over time) group buildings according to the style of some of their key features. They use this to help them work out when a building was constructed. Inside Canterbury Cathedral you can see several different types of arches. Arches have been used for thousands of years to help to give buildings strength.
      </p>
      <Figure
        caption="Illustration showing the arches present in the Cathedral. The first row shows Romanesque or Norman arches and the row below shows Gothic arches."
        className="page-right"
      >
        <StaticImage
          src="./arches.png"
          alt="The different styles of arches found in the Cathedral"
        />
      </Figure>
      <DiscussBox>Who influenced the change in the Cathedral’s design?</DiscussBox>

      <h2>Forces</h2>
      <p className="page-left">
        The walls of a massive stone building such as the Cathedral need to be very strong, particularly at ground level to support the heavy building above. You can see just how thick the walls are by looking at the window openings.
      </p>
      <Figure
        caption="Image showing a flying buttress."
        className="page-right row-2"
      >
        <StaticImage
          src="./buttress.png"
          alt="Buttress"
        />
      </Figure>
      <p className="page-left">
        The Cathedral roof is also very heavy and the weight of it causes a force called thrust which travels down the Cathedral wall. This force (thrust) pushes the Cathedral walls slowly outwards and if nothing is done to support the walls the building will collapse.
      </p>
      <p className="page-left">
        To solve this problem, the Cathedral builders realised they needed to give the walls extra support to stop them falling down. These extra supports are called buttresses and they push the walls back in keeping them upright. You can see the buttresses on the outside of the nave wall. They are built at right angles to the main wall and are located between the windows.
      </p>
      <Figure
        caption="Photograph of the Cathedral building. Can you find the buttresses?"
        className="page-right row-3"
      >
        <StaticImage
          src="./buttresses.jpg"
          alt="Buttresses outside the Nave"
        />
      </Figure>
      <p className="page-left">
        The Cathedral walls also need support much higher up. Instead of solid buttresses pushing the walls back in, this time we can see something called flying buttresses. These are arches that lean or push against the walls again helping to keep the tops of the walls straight and not leaning out.
      </p>
      <DefinitionBox title="Thrust" className="page-left">
        A pushing force which travels in one direction.
      </DefinitionBox>

      <YouTubeVideo id="P90VqmexaCU" />

      <ToSeeBox
        items={[
          {
            location: "Outside the Cathedral",
            items: [
              {
                title: "Buttresses",
                content: "These small walls sit at right angles to the main Nave wall. They help to keep the walls straight and stop them gradually pushing outwards."
              }
            ]
          },
          {
            location: "Bell Harry Tower",
            items: [
              {
                title: "Fan Vaulting",
                content: "When standing at the top of the pulpitum steps, look up into the Bell Harry Tower. You can see the beautiful vaulting above, which looks like an open fan."
              }
            ]
          },
          {
            location: "South East Transcept",
            items: [
              {
                title: "Row of three arches",
                content: "These three arches show how the style of architecture changed over time. Thought to have originally been three Romanesque (rounded) arches, the third has been re-carved in the early gothic style. Perhaps this was to show the monks the new style of architecture. "
              }
            ]
          },
          {
            location: "Western Crypt",
            items: [
              {
                title: "Romanesque arches",
                content: "The western crypt is the oldest part of the existing Cathedral building and dates from the late 11th century. There are many examples of the rounded Norman or Romanesque arches typical of the time this section of the Cathedral was built and many of the pillars have carvings called capitals at the top. "
              }
            ]
          },
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="How Did They Build That activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/HowDidTheyBuildThat.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘How did they build that ’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "Stone", to: "/how-did-they-build-that/stone" },
          {
            name: "Stained Glass",
            to: "/how-did-they-build-that/stained-glass",
          },
          { name: "How is glass made?", to: "/how-did-they-build-that/glass" },
        ]}
      />

      <hr />

      <CopyrightBox />
    </Page>
  </Layout>
)

export default BuildingTheCathedral;